// src/MasonryHero.js
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import './MasonryHero.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fade from 'react-bootstrap/Fade'
import Card from 'react-bootstrap/Card';

// Image by benzoix on Freepik
// attribute this for design site about section

function MasonryHero() {

  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    // Trigger fade-in when the component mounts
    setFadeIn(true);
  }, []);


  const [selectedImage, setSelectedImage] = useState(null);

  const openImagePopup = (imgSrc) => {
    setSelectedImage(imgSrc);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  return (

    <section id='websites-section' className='entire-gallery-section'>

    <Container className='masonry-hero-container'>
        <div className="photo-gallery">
          <div className='column'>

          {/* ************************COLUMN 1************************ */}

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/burgerRestauranSqsp.jpeg')}>
      <Card.Img variant="top" src="/images/burgerRestauranSqsp.jpeg" alt='Squarespace Restaurant Theme'/>
      <Card.Body className='flex-column'>
        <Card.Text>
          Squarespace Restaurant Mobile View
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/squarespFitness.jpeg')}>
      <Card.Img variant="top" src="/images/squarespFitness.jpeg" alt='Squarespace Personal Trainer Website'/>
      <Card.Body className='flex-column'>
        <Card.Text>
          Squarespace Personal Trainer Website
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/veganRest2.jpeg')}>
      <Card.Img variant="top" src="/images/veganRest2.jpeg" />
      <Card.Body className='flex-column'>
        <Card.Text>
          Coded by Maxy - Vegan Restaurant
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

  {/* <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/realEstate1.jpeg')}>
      <Card.Img variant="top" src="/images/realEstate1.jpeg" />
      <Card.Body className='flex-column'>
        <Card.Text>
          Wix Real Estate Website
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade> */}

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/hairSalon1Wix.jpeg')}>
      <Card.Img variant="top" src="/images/hairSalon1Wix.jpeg" />
      <Card.Body className='flex-column'>
        <Card.Text>
          Wix Hair Salon Website
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>



  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/purpleFitnessAppWix.jpeg')}>
      <Card.Img variant="top" src="/images/purpleFitnessAppWix.jpeg" />
      <Card.Body className='flex-column'>
        <Card.Text>
          Wix Fitness Mobile App
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/nonprofit1Wix.jpeg')}>
      <Card.Img variant="top" src="/images/nonprofit1Wix.jpeg" alt='Wix Nonprofit Site'/>
      <Card.Body className='flex-column'>
        <Card.Text>
          Wix Nonprofit Website
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

  <Fade in={fadeIn} className='fadeEffect'>
    <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/restaurantSqsp.jpeg')}>
      <Card.Img variant="top" src="/images/restaurantSqsp.jpeg" alt='Squarespace Restaurant Site'/>
      <Card.Body className='flex-column'>
        <Card.Text>
          Squarespace Restaurant Site
        </Card.Text>
      </Card.Body>
    </Card>
  </Fade>

          </div>
          {/* ************************COLUMN 2************************ */}
          <div className='column'>
          <Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/flowerShopSqsp.jpeg')}>
    <Card.Img variant="top" src="/images/flowerShopSqsp.jpeg" alt='Squarespace Flower Shop'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Squarespace Flower Shop
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/interior-design.jpeg')}>
    <Card.Img variant="top" src="/images/interior-design.jpeg" alt='Interior Design Site - Custom Coded by Maxy'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Coded by Maxy - Interior Design Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/glamourShotsMobileWix.jpeg')}>
    <Card.Img variant="top" src="/images/glamourShotsMobileWix.jpeg" alt='Squarespace Photography Site'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Squarespace Photography Mobile Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/storeSkincare2Shpfy.jpeg')}>
    <Card.Img variant="top" src="/images/storeSkincare2Shpfy.jpeg" alt='Shopify Skincare Products Theme'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Shopify Skincare Products Theme
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>


          <Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/veganRest3.jpeg')}>
    <Card.Img variant="top" src="/images/veganRest3.jpeg" alt='Vegan Restaurant Site Custom Coded by Maxy'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Coded by Maxy - Vegan Restaurant
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/electronicsStoreTallShpfy.jpeg')}>
    <Card.Img variant="top" src="/images/electronicsStoreTallShpfy.jpeg" alt='Shopify Electronics Store Theme'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Shopify Electronics Store Theme
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/storeJewelryShpfy.jpeg')}>
    <Card.Img variant="top" src="/images/storeJewelryShpfy.jpeg" alt='Shopify Jewelry Store Theme'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Shopify Jewelry Store Theme
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

          </div>



          {/* ************************COLUMN 3************************ */}

          <div className='column'>
          <Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/veganRest1.jpeg')}>
    <Card.Img variant="top" src="/images/veganRest1.jpeg" alt='Vegan Restaurant Site Custom Coded by Maxy'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Coded by Maxy - Vegan Restaurant
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/weddingSqsp.jpeg')}>
    <Card.Img variant="top" src="/images/weddingSqsp.jpeg" alt='Squarespace Wedding Site'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Squarespace Wedding Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/hairSalon2Wix.jpeg')}>
    <Card.Img variant="top" src="/images/hairSalon2Wix.jpeg" alt='Wix Hair Salon Website'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Wix Hair Salon Website
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/design-about section.jpeg')}>
    <Card.Img variant="top" src="/images/design-about section.jpeg" alt='Interior Design Site, Image by benzoix on Freepik'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Coded by Maxy - Interior Design Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/apparelStore2Wix.jpeg')}>
    <Card.Img variant="top" src="/images/apparelStore2Wix.jpeg" alt='Wix Apparel Store'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Wix Apparel Store
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/lawFirmWix.jpeg')}>
    <Card.Img variant="top" src="/images/lawFirmWix.jpeg" alt='Wix Law Firm Site'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Wix Law Firm Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>


<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/lawFirmWix.jpeg')}>
    <Card.Img variant="top" src="/images/cafeSqsp.jpeg" alt='Wix Cafe Site'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Wix Cafe Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/lawFirmWix.jpeg')}>
    <Card.Img variant="top" src="/images/lawFirmWix.jpeg" alt='Wix Law Firm Site'/>
    <Card.Body className='flex-column'>
      <Card.Text>
        Wix Law Firm Site
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

<Fade in={fadeIn} className='fadeEffect'>
  <Card className='card-style-masonry photo' onClick={() => openImagePopup('/images/conferenceEventWix.jpeg')}>
    <Card.Img variant="top" src="/images/conferenceEventWix.jpeg" alt='Wix Conference Speakers'/>
    <Card.Body className='flex-column'>
      <Card.Text>
      Wix Conference Website
      </Card.Text>
    </Card.Body>
  </Card>
</Fade>

          </div>
        </div>


      {selectedImage && (
        <div className="popup-overlay" onClick={closeImagePopup}>
          <div className="popup-content">
            <img src={selectedImage} alt="Enlarged" />
          </div>
        </div>
      )}

    </Container>
  </section>

  );
}

export default MasonryHero;