import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-section" id='footer'>
      <Container>
        <Row>

          <Col className="contactMeText">
            <h2>Contact Me:</h2>
            <p>Tech By Maxy</p>
            <p>Phone: (716) 534-8868</p>
            <p>Email: hello@maxycopeland.com</p>
          </Col>
        </Row>

        <Row className='mt-3'>
            <Col className="footer-item">
            <h5>Copyright</h5>
            <p>&copy; 2024 MaxyCopeland. All rights reserved.</p>
            </Col>            
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
