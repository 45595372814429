import { React, useState} from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './style.css';

function MyNavbar() {

  const [expanded, setExpanded] = useState(false);

  // Toggle the collapse menu
  const handleToggle = () => setExpanded(!expanded);

  // Close the collapse menu after a link is clicked
  const handleLinkClick = () => setExpanded(false);
  
  return (
    <Navbar bg="light" expand="lg" sticky="top" className="custom-navbar">
      <Container className="justify-content-between">
        <Navbar.Brand href="#">
          <img src="/images/rhinoLogo.png" alt="website logo rhino" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" className='custom-toggler'>
          <Button variant="secondary">Menu</Button>                      
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarNav" className="justify-content-center custom-toggler-menu">
          <Nav className="ml-auto">
            <Nav.Link href="#hero-section-pricing" onClick={handleLinkClick}>Pricing</Nav.Link>
            <Nav.Link href="#websites-section" onClick={handleLinkClick}>Websites</Nav.Link>
            <Nav.Link href="#" onClick={handleLinkClick}>About</Nav.Link>
            <Nav.Link href="#footer" onClick={handleLinkClick}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
