// src/App.js
import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Container, Row, Col } from 'react-bootstrap';
import './style.css';
import MasonryHero from './MasonryHero';
import MyNavbar from './MyNavbar';
import Fade from 'react-bootstrap/Fade'
import ContactForm from './ContactForm';
import PriceModal from './PriceModal';
import Footer from './Footer';



function App() {

  useEffect(() => {
    // This will hide horizontal overflow for the entire app
    document.body.style.overflowX = 'hidden';
}, []);

  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
      // Trigger fade-in when the component mounts
      setFadeIn(true);
  }, []);

  
  return (
    <main>
      <div>

        {/* Navbar */}
        <MyNavbar />

    <div> {/*Hero Image sections for mobile and desktop  */}
        {/*  Hero Image Card for mobile view */}
      <div className='mobile-only-card'> 
        <Fade in={fadeIn} className='fadeEffect-quick'>
          <div className="container-wrapper">
            <Container className="my-5">
              <Row className='card-row-height'>
                <Col>
                  <Card className='price-card-height'>
                    <Card.Img variant="top" src="/images/simpleBeaSites.jpeg"/>
                    <Card.Body className='graybackground flex-column'>
                    <div className='text-center'>
                      <Button variant="primary">Check Them Out</Button>{' '}                       
                    </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </ Fade>
      </div>


      {/* Desktop View Hero Image Price Cards */}
      <div className="container-wrapper desktop-only-display" id='hero-section-pricing'>
          <Container className="my-5">
            <Row className='card-row-height'>
              <Col xs={6} md={4} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/simple.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                    <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      Professional small business sites, one-page websites, portfolios, contact forms, listing services offered, professional email address (me@mybusinessname.com) includes Wix, Squarespace and custom coded sites.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={4} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/complex.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                    <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      E-Commerce sites, online stores, online reservations, member log-in page, analytics, AI chatbots, integration with other software platforms, sites with video and media and Shopify sites.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={4} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/mobile.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                    <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      Apps that make it easy to order food, shop, be entertained or use a service on a smartphone. Popular examples are Facebook and Instagram apps, Uber, DoorDash and CashApp.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <MasonryHero />


      {/* Price Cards MOBILE ONLY */}
      <div className="container-wrapper mobile-only-display" id='hero-section-pricing'>
          <Container className="my-5">
            <Row className='card-row-height mb-4'>
              <Col xs={6} md={3} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/simple.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                    <div className='text-center'>
                      <PriceModal title="Simple Beautiful Websites" body="Professional small business sites, one-page websites, portfolios, contact forms, listing services offered, professional email address (me@mybusinessname.com) includes Wix, Squarespace and custom coded sites." />                    
                    </div>
                    {/* <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      Professional small business sites, one-page websites, portfolios, contact forms, listing services offered, professional email address (me@mybusinessname.com) includes Wix, Squarespace and custom coded sites.
                    </Card.Text> */}
   

                  </Card.Body>
                </Card>
              </Col>
              <Col xs={6} md={3} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/complex.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                  <div className='text-center'>
                    <PriceModal title="Complex Websites and Web Applications" body="E-Commerce sites, online stores, online reservations, member log-in page, analytics, AI chatbots, integration with other software platforms, sites with video and media and Shopify sites." />                    
                  </div>
                    {/* <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      E-Commerce sites, online stores, online reservations, member log-in page, analytics, AI chatbots, integration with other software platforms, sites with video and media and Shopify sites.
                    </Card.Text> */}
                  </Card.Body>
                </Card>

              </Col>

              </Row>  
              <Row>
              <Col xs={6} md={3} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/mobile.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                  <div className='text-center'>
                    <PriceModal title="Mobile Applications" body="Apps that make it easy to order food, shop, be entertained or use a service on a smartphone. Popular examples are Facebook and Instagram apps, Uber, DoorDash and CashApp." />                
                  </div>
                    {/* <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      Apps that make it easy to order food, shop, be entertained or use a service on a smartphone. Popular examples are Facebook and Instagram apps, Uber, DoorDash and CashApp.
                    </Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={6} md={3} className="d-flex">
                <Card className='price-card-height'>
                  <Card.Img variant="top" src="images/codingsvcs.jpeg" />
                  <Card.Body className='graybackground flex-column'>
                  <div className='text-center'>
                    <PriceModal title="React, Bootstrap coding and other services" body="Website updates and maintenance, freelance coding services, React Components, web scraping and automation tools." />
                  </div>
                    {/* <Card.Text style={{ color: '#2b2b2b' }} className='card-text-min-height'>
                      Apps that make it easy to order food, shop, be entertained or use a service on a smartphone. Popular examples are Facebook and Instagram apps, Uber, DoorDash and CashApp.
                    </Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        {/* Contact Form */}
        {/* <ContactForm /> */}


        <div className="construction-banner mt-5">
      <Container>
        <p className="text-center banner-text">Website Under Construction **** Reach out for a quote **** Projects will resume in October 2024</p>
      </Container>
    </div>

    
        {/* Footer */}
        <Footer />
      </div>
    </main>

  );
}

export default App;
